import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {RequestOptionsArgs} from "@angular/http";
import {HttpHeaders} from "@angular/common/http";
import {Club} from "../dataModules/club";
import {Observable} from "rxjs";
import {tap, catchError} from "rxjs/internal/operators";
import {WebsiteUser} from "../dataModules/website_user";
import {HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../services/authentication.service";
import {District} from "../dataModules/district";
import {GameUser} from "../dataModules/game_user";

// import {District} from "../dataModules/district";

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  myUrl: string;
  httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private http: HttpClient, private _service: AuthenticationService) {
    this.myUrl = environment.api_url;
  }


  handleHttpResponse(res) {
    console.log(res);
    // var json = res.json();


    switch (res['result']) {
      case -1:
        // invalid token
        this._service.logout("Login error. Please login again");
        return;
      case -2:
        // token expired
        // this._service.logout("Login error. Please login again");
        return;
      default:
        return res;
    }
  }

  // private getStatus(status){
  //   return "asd";
  // }

  private getHttpMethod(url, params?) {
    const mHttpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    };

    if (params != null) {
      console.log(mHttpOptions);
    }

    console.log("connecting:" + this.myUrl + url, mHttpOptions);
    return this.http.get<any>(this.myUrl + url)
      .pipe(
        tap(res => this.handleHttpResponse(res))
      );
  }

  deleteHttpMethod(url) {
    console.log(url);

    return this.http.delete(url).subscribe(
      res => {
        return this.handleHttpResponse(res);
      }
    );
  }

  putHttpMethod(url, body, options?) {
    console.log(url);
    console.log(body);
    return this.http.put(this.myUrl + url, body, options).pipe(
      tap(res => this.handleHttpResponse(res))
    );
  }

  postHttpMethod(url, body, options?) {
    console.log(url);
    console.log(body);
    return this.http.post(this.myUrl + url, body, options).pipe(
      tap(res => this.handleHttpResponse(res))
    );
  }


  getClubs(): Observable<Club[]> {
    return this.getHttpMethod("club");
  }

  getGameUsers(): Observable<GameUser[]> {
    return this.getHttpMethod("game_user");
  }

  getWebsiteUsers(): Observable<WebsiteUser[]> {
    return this.getHttpMethod("website_user");
  }

  getDistricts(): Observable<District[]> {
    return this.getHttpMethod("district");
  }

  userLogin(username, password): Observable<WebsiteUser> {
    return this.getHttpMethod("login?username=" + username + "&password=" + password);
  }

  searchGameUser(selectedClubs: number[]): Observable<GameUser[]> {
    var userId = localStorage.getItem("id");
    var queryString = "?id=" + userId + "&token=" + localStorage.getItem("token");

    for (var i = 0; i < selectedClubs.length; i++) {
      queryString += "&club" + i + "=" + selectedClubs[i];
    }

    return this.getHttpMethod("searchGameUser" + queryString);
  }

  searchGameUserForOne(district_id: number, club_id: number): Observable<GameUser> {
    var userId = localStorage.getItem("id");
    var queryString = "?id=" + userId + "&token=" + localStorage.getItem("token") + "&district_id=" + district_id
      + "&club_id=" + club_id ;

    return this.getHttpMethod("searchGameUserForOne" + queryString);
  }

  comfirmGameUser() {
    var userId = localStorage.getItem("id");
    var queryString = "?token=" + localStorage.getItem("token");

    return this.getHttpMethod("confirm_game_users/" + userId + queryString);
  }

  clearGameUser() {
    var userId = localStorage.getItem("id");
    var queryString = "?token=" + localStorage.getItem("token");

    return this.getHttpMethod("clear_game_users/" + userId + queryString);
  }

  clearGameUserOne(district_id) {
    var userId = localStorage.getItem("id");
    var queryString = "?token=" + localStorage.getItem("token") + "&district_id=" + district_id;

    return this.getHttpMethod("clear_game_user/" + userId + queryString);
  }

  getCurrentGameAccount() {
    var userId = localStorage.getItem("id");
    var queryString = "?token=" + localStorage.getItem("token");

    return this.getHttpMethod("getCurrentGameAccount/" + userId + queryString);
  }

  addClub(name) {
    var queryString = "?token=" + localStorage.getItem("token");
    var body = {name: name};
    return this.postHttpMethod("club" + queryString, body);
  }

  addDistrict(name) {
    var queryString = "?token=" + localStorage.getItem("token");
    var body = {name: name};
    return this.postHttpMethod("district" + queryString, body);
  }

  addWebUser(user) {
    var queryString = "?token=" + localStorage.getItem("token");
    var body = user;
    return this.postHttpMethod("website_user" + queryString, body);
  }

  updateWebUser(id, user) {
    var queryString = "?token=" + localStorage.getItem("token");
    var body = user;
    return this.putHttpMethod("website_user/" + id + queryString, body);
  }

  addGameUser(user) {
    var queryString = "?token=" + localStorage.getItem("token");
    var body = user;
    return this.postHttpMethod("game_user" + queryString, body);
  }


  updateGameUser(id, user) {
    var queryString = "?token=" + localStorage.getItem("token");
    var body = user;
    return this.putHttpMethod("game_user/" + id + queryString, body);
  }


}
