import {Club} from "./club";

export class GameUser {
  _id: number;
  user_id: number;
  username: string;
  password: string;
  // district: string;
  // club: string;
  district_id: number;
  club_id: number;
  status: number;
  clubs: Club[];


  copy(gameUser: GameUser) {
    if (gameUser._id == null) {
      // normal
      this._id = gameUser._id;
      this.username = "-";
      this.district_id = gameUser.district_id;
      this.club_id = gameUser.club_id;
      this.status = 1;

    } else if (gameUser._id > 0) {
      // normal
      this._id = gameUser._id;
      this.user_id = gameUser.user_id;
      this.username = gameUser.username;
      this.password = gameUser.password;
      this.district_id = gameUser.district_id;
      this.club_id = gameUser.club_id;
      this.status = gameUser.status;
      this.status = 3;
      this.clubs = gameUser.clubs;
    } else {
      // no available
      this._id = 0;
      this.user_id = 0;
      this.username = "沒有可使用的帳號";
      this.password = "";
      this.district_id = gameUser.district_id;
      this.club_id = gameUser.club_id;
      this.status = 0;
      this.clubs = gameUser.clubs;
    }
  }

  empty() {
    this._id = null;
    this.user_id = null;
    this.username = "-";
    this.password = "";
    this.status = 3;
  }

  constructor(gameUser: GameUser) {
    if (gameUser._id != 0) {
      // normal
      this._id = gameUser._id;
      this.user_id = gameUser.user_id;
      this.username = gameUser.username;
      this.password = gameUser.password;
      // this.district = gameUser.district;
      // this.club = gameUser.club;
      this.district_id = gameUser.district_id;
      this.club_id = gameUser.club_id;
      this.status = gameUser.status;
      this.clubs = gameUser.clubs;
      // this.statusString = gameUser.statusString;
      // this.classString = gameUser.classString;
    } else {
      // no available
      this._id = 0;
      this.user_id = 0;
      this.username = "沒有可使用的帳號";
      this.password = "";
      // this.district = gameUser.district;
      // this.club = gameUser.club;
      this.district_id = gameUser.district_id;
      this.club_id = gameUser.club_id;
      this.status = 0;
      this.clubs = gameUser.clubs;
    }


  }

  // constructor(district_id: number) {
  //   this.district_id = gameUser.district_id;
  //
  // }


  public getStatus(): string {
    switch (this.status) {
      case 1:
        return "正常";
      case 2:
        return "待確認";
      case 3:
        return "使用中";
    }
  }

  public getClass(): string {
    switch (this.status) {
      case 1:
        return "my-col-normal mat-row";
      case 2:
        return "my-col-waiting mat-row";
      case 3:
        return "my-col-using mat-row";
      default:
        return "my-col-no-acc mat-row";
    }
  }
}
