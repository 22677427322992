import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
// import {GenGameUserComponent} from './components/gen-game-user/gen-game-user.component';
import {AppRoutingModule} from './app-routing.module';
import {
  MatInputModule, MatButtonModule, MatSnackBarModule, MatToolbarModule, MatSelectModule,
  MatListModule, MatTableModule, MatDialogModule, MatCheckboxModule
} from "@angular/material";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MessagesComponent} from './components/messages/messages.component';
import {HttpClientModule} from "@angular/common/http";
import {SearchComponent} from './components/search/search.component';
import {DistrictComponent} from './components/district/district.component';
import {ClubComponent} from './components/club/club.component';
import {GameUserComponent} from './components/game-user/game-user.component';
import {WebsiteUserComponent} from './components/website-user/website-user.component';
import {TwoValueDialogComponent} from "./dialog/two-value-dialog/two-value-dialog.component";
import {WebsiteUserDialogComponent} from "./dialog/website-user/website-user-dialog.component";
import {GameUserDialogComponent} from './dialog/game-user-dialog/game-user-dialog.component';
import { HackAddUserComponent } from './components/hack-add-user/hack-add-user.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MessagesComponent,
    SearchComponent,
    DistrictComponent,
    ClubComponent,
    GameUserComponent,
    WebsiteUserComponent,
    TwoValueDialogComponent,
    WebsiteUserDialogComponent,
    GameUserDialogComponent,
    HackAddUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatInputModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSelectModule,
    MatListModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule
  ],
  providers: [],
  entryComponents: [TwoValueDialogComponent, WebsiteUserDialogComponent, GameUserDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {


}
